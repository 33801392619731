<template>
	<OrdersList :orders="orders" :loading="isLoading" @reload="loadData" />
</template>

<script>
import { OrdersList } from './components'
import { mapActions, mapGetters } from 'vuex'
import config from '@/config/defaultSettings'

export default {
	name: 'PendingOrders',
	components: {
		OrdersList,
	},
	computed: {
		...mapGetters({
			isMerchantActive: 'merchants/isMerchantActive',
			isAuthorized: 'merchants/isAuthorized',
			selectedMerchantId: 'merchants/selectedMerchantId',
			orders: 'orders/pendingOrders',
			isLoading: 'orders/isLoading',
		}),
	},
	watch: {
		$route: {
			handler: 'loadData',
			immediate: true,
		},
	},
	methods: {
		...mapActions('orders', ['getOrders']),
		...mapActions('merchants', ['getPaymentMethods']),
		...mapActions('notifications', ['resetNotifications']),
		async loadData() {
			if (!this.isAuthorized || !this.isMerchantActive) return

			try {
				await this.getOrders({
					merchantId: this.selectedMerchantId,
					params: {
						order_type: 'PENDING',
						page: 1,
						page_size: config.defaultPageSize,
					},
				})
				this.getPaymentMethods({
					merchantId: this.selectedMerchantId,
					availables: true,
				})
			} finally {
				this.resetNotifications('PENDING')
			}
		},
	},
}
</script>
